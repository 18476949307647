import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "pspdList";
const modelPath = "/anggota/tag/pspd/";

class PSPDList extends ListModelBase {
  constructor(tagId) {
    super(modelName, modelPath, {tag: tagId});
  }
}

export default PSPDList;
